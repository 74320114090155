import { RouteIds, Routes } from "~/constants/routes";
import { useRawFetcher } from "~/hooks/use-raw-fetcher";

export function useNewOrdersCount({ onFetched }: { onFetched: (total: number) => void }) {
  const [fetch] = useRawFetcher<{ totalUnopenedOrders: number }>({
    onError: (response) => {
      alert(response.error);
    },
    onSuccess: (data) => {
      onFetched(data.totalUnopenedOrders);
    },
  });

  function fetchNewOrdersCount() {
    fetch.load(
      Routes.loaders.wisher.wishes.unopenedOrders(),
      RouteIds.loaders.wisher.wishes.unopenedOrders(),
    );
  }

  return {
    fetchNewOrdersCount,
  };
}
