import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";

import { useAuth } from "~/hooks/use-auth";

import { useNewOrdersCount } from "../hooks/use-new-orders-count";

export type WisherContextValues = {
  totalNewOrders: number;
  decreaseTotalNewOrders: () => void;
};

export enum WisherActionType {
  DECREASE_TOTAL_NEW_ORDERS = "decreaseTotalNewOrders",
  UPDATE_TOTAL_NEW_ORDERS = "updateTotalNewOrders",
}

export type WisherAction = {
  type: WisherActionType;
  payload?: Partial<WisherContextValues>;
};

const INIT_STATE: WisherContextValues = {
  totalNewOrders: 0,
  decreaseTotalNewOrders: () => {},
};

const WisherContext = createContext<WisherContextValues | string>(
  "Invalid usage of WisherContext. Please ensure you wrap your components with WisherContextProvider.",
);

function wisherReducer(
  wisherValues: WisherContextValues,
  action: WisherAction,
): WisherContextValues {
  switch (action.type) {
    case WisherActionType.DECREASE_TOTAL_NEW_ORDERS: {
      return {
        ...wisherValues,
        totalNewOrders: wisherValues.totalNewOrders - 1,
      };
    }
    case WisherActionType.UPDATE_TOTAL_NEW_ORDERS: {
      return {
        ...wisherValues,
        totalNewOrders: action?.payload?.totalNewOrders || 0,
      };
    }
    default: {
      throw new Error("Unknown action: " + action.type);
    }
  }
}

export function WisherContextProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const [state, dispatch] = useReducer(wisherReducer, INIT_STATE);
  const { fetchNewOrdersCount } = useNewOrdersCount({
    onFetched: (total) =>
      dispatch({
        type: WisherActionType.UPDATE_TOTAL_NEW_ORDERS,
        payload: {
          totalNewOrders: total,
        },
      }),
  });

  function decreaseTotalNewOrders() {
    dispatch({
      type: WisherActionType.DECREASE_TOTAL_NEW_ORDERS,
    });
  }

  useEffect(() => {
    if (auth.wisher?.id) {
      fetchNewOrdersCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.wisher?.id]);

  return (
    <WisherContext.Provider
      value={{
        ...state,
        // TODO: Avoid negative value. Find a better way to avoid race condition
        // Because the totalNewOrders count is fetched in the client, so if users click open order (call decreaseTotalNewOrders) before this fetch finished, the total will be negative
        totalNewOrders: Math.max(state.totalNewOrders, 0),
        decreaseTotalNewOrders,
      }}
    >
      {children}
    </WisherContext.Provider>
  );
}

export function useWisher() {
  const context = useContext(WisherContext);

  if (typeof context === "string") {
    throw new TypeError(context);
  }

  return context;
}
