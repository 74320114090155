import type { SerializeFrom } from "@remix-run/node";
import { useRouteLoaderData } from "@remix-run/react";

import { RouteIds } from "~/constants/routes";
import type { Wishlist } from "~/types";

export function useWishlist() {
  return (useRouteLoaderData(RouteIds.wisher.layout()) as { wishlist: SerializeFrom<Wishlist> })
    .wishlist;
}
